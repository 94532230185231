import client from "./api";
import endpoints from "./intoCultureApiClientEndpoints";
import { buildDateTimeString } from "../helpers/dateHelper";
import user from "./userServices/userService";

/**
 * Into Culture API Client
 */
const intoCultureApiClient = () => {
  const getAuthUser = () => {
    return client.get(endpoints.authUser(), false);
  };

  const getActiveMembership = () => {
    return client.get(endpoints.activeMembership(), false);
  };

  const getIdealIssuers = () => {
    return client.get(endpoints.idealIssuers);
  };

  /**
   * Gets availability for product
   * To get availabilty for 1 day, startDate and endDate should be same date.
   *
   * @param {string} slug - Unique slug of product
   * @param {object} obj - Arguments passed to availability endpoint
   * @param {string} obj.startDate - Startdate of interval of dates to fetch YYYY-mm-dd
   * @param {string} obj.endDate - Enddate of interval to fetch YYYY-mm-dd
   *
   * @returns object
   */
  const getProductAvailability = (slug, { startDate, endDate }) => {
    if (!startDate) {
      return;
    }

    if (!endDate) {
      endDate = startDate;
    }

    return client.get(
      endpoints.productAvailability(slug, { startDate, endDate }),
      false
    );
  };

  const getOrder = async (orderId) => {
    await user.getUser(true); // using session instead of token, making sure we have the latest token.
    const orderParams = {
      order: orderId,
    };

    try {
      return client.get(endpoints.order(orderParams));
    } catch (err) {
      console.error("apiclient error getOrder", err);
    }
  };

  const postOrder = async ({ date, timeslot, tickets, paymentMethod }) => {
    const dateTime = buildDateTimeString(date, timeslot);
    await user.getUser(true); // using session instead of token, making sure we have the latest token.

    const orderParams = {
      ...(dateTime && { dateTime }),
      variants: tickets
        .filter(({ amount }) => amount > 0)
        .map(({ slug, amount }) => ({
          slug,
          amount,
        })),
      paymentMethod,
    };

    return client.post(endpoints.order(orderParams), null, false);
  };

  // clickoutProducts assume there is 1 variant, and the user buys a single item
  const getClickOutProductUrl = async ({ variants }) => {
    await user.getUser(true); // using session instead of token, making sure we have the latest token.
    const orderParams = {
      variants: variants.map(({ slug }) => ({
        slug,
        amount: 1,
      })),
    };

    try {
      return await client.post(endpoints.order(orderParams), {}, false);
    } catch (err) {
      console.error("apiclient error getClickOutProductUrl", err);
      throw err;
    }
  };

  const getTickets = async (orderId) => {
    await user.getUser(); // using session instead of token, making sure we have the latest token.
    const ticketParams = {
      token: user.getSessionToken(),
      order: orderId,
    };

    try {
      return client.get(endpoints.tickets(ticketParams));
    } catch (err) {
      console.error("apiclient error getTickets", err);
    }
  };

  return {
    getAuthUser,
    getIdealIssuers,
    getOrder,
    getProductAvailability,
    postOrder,
    getClickOutProductUrl,
    getTickets,
    getActiveMembership,
  };
};

export default intoCultureApiClient();
